<template>
  <div>
    <section id="card-content-types">
      <h1 class="mt-3">
        Жюри
      </h1>
      <b-row>

        <b-col
            md="12"
            lg="12"
        >
          <!-- table -->
          <vue-good-table
              class="songs-list"
              :columns="columns"
              :rows="usersList"
              :search-options="{
                enabled: false,
                }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: false, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: false,
              }"
              :line-numbers="!showOnlyTitles"
          >
            <template
                slot="table-row"
                slot-scope="props"
            >

              <!-- Column: Name -->
              <span
                  v-if="props.column.field === 'name'"
                  class="text"
              >

                <b-avatar
                    :src="props.row.image"
                    class="mx-1"
                />
                <span class="text">{{ props.row.name }}</span>
              </span>

              <!-- Column: Progress -->
              <span v-else-if="props.column.field === 'albums_ru'">
                <span class="progress-wrapper">
                  <b-card-text class="mb-0">
                    Альбомов: {{ props.row.votes_stats.albums.ratedRu }} / {{ props.row.votes_stats.albums.totalRu }}
                  </b-card-text>
                  <b-progress
                      :value="props.row.votes_stats.albums.ratedRu"
                      :max="props.row.votes_stats.albums.totalRu"
                      :variant="variant(Math.round(props.row.votes_stats.albums.ratedRu / props.row.votes_stats.albums.totalRu * 100))"
                      striped
                  />
                </span>
              </span>

              <!-- Column: Progress -->
              <span v-else-if="props.column.field === 'albums_en'">
                <span class="progress-wrapper">
                  <b-card-text class="mb-0">
                    Альбомов: {{ props.row.votes_stats.albums.ratedEn }} / {{ props.row.votes_stats.albums.totalEn }}
                  </b-card-text>
                  <b-progress
                      :value="props.row.votes_stats.albums.ratedEn"
                      :max="props.row.votes_stats.albums.totalEn"
                      :variant="variant(Math.round(props.row.votes_stats.albums.ratedEn / props.row.votes_stats.albums.totalEn * 100))"
                      striped
                  />
                </span>
              </span>

              <!-- Column: Progress -->
              <span v-else-if="props.column.field === 'videos_ru'">
                <span class="progress-wrapper">
                  <b-card-text class="mb-0">
                    Клипов RU: {{ props.row.votes_stats.videos.ratedRu }} / {{ props.row.votes_stats.videos.totalRu }}
                  </b-card-text>
                  <b-progress
                      :value="props.row.votes_stats.videos.ratedRu"
                      :max="props.row.votes_stats.videos.totalRu"
                      :variant="variant(Math.round(props.row.votes_stats.videos.ratedRu / props.row.votes_stats.videos.totalRu * 100))"
                      striped
                  />
                </span>
              </span>

              <!-- Column: Progress -->
              <span v-else-if="props.column.field === 'videos_en'">
                <span class="progress-wrapper">
                  <b-card-text class="mb-0">
                    Клипов EN: {{ props.row.votes_stats.videos.ratedEn }} / {{ props.row.votes_stats.videos.totalEn }}
                  </b-card-text>
                  <b-progress
                      :value="props.row.votes_stats.videos.ratedEn"
                      :max="props.row.votes_stats.videos.totalEn"
                      :variant="variant(Math.round(props.row.votes_stats.videos.ratedEn / props.row.votes_stats.videos.totalEn * 100))"
                      striped
                  />
                </span>
              </span>

              <!-- Column: Progress -->
              <span v-else-if="props.column.field === 'songs_ru'">
                <span class="progress-wrapper">
                  <b-card-text class="mb-0">
                    Песен RU: {{ props.row.votes_stats.songs.ratedRu }} / {{ props.row.votes_stats.songs.totalRu }}
                  </b-card-text>
                  <b-progress
                      :value="props.row.votes_stats.songs.ratedRu"
                      :max="props.row.votes_stats.songs.totalRu"
                      :variant="variant(Math.round(props.row.votes_stats.songs.ratedRu / props.row.votes_stats.songs.totalRu * 100))"
                      striped
                  />
                </span>
              </span>

              <!-- Column: Progress -->
              <span v-else-if="props.column.field === 'songs_en'">
                <span class="progress-wrapper">
                  <b-card-text class="mb-0">
                    Песен EN: {{ props.row.votes_stats.songs.ratedEn }} / {{ props.row.votes_stats.songs.totalEn }}
                  </b-card-text>
                  <b-progress
                      :value="props.row.votes_stats.songs.ratedEn"
                      :max="props.row.votes_stats.songs.totalEn"
                      :variant="variant(Math.round(props.row.votes_stats.songs.ratedEn / props.row.votes_stats.songs.totalEn * 100))"
                      striped
                  />
                </span>
              </span>

              <!-- Column: Progress -->
              <span v-else-if="props.column.field === 'votes_count'">
                <span class="progress-wrapper">
                  <b-card-text class="mb-0">
                    Всего: {{
                      props.row.votes_stats.albums.rated +
                      props.row.votes_stats.videos.rated +
                      props.row.votes_stats.songs.rated
                    }} / {{
                      props.row.votes_stats.albums.total +
                      props.row.votes_stats.videos.total +
                      props.row.votes_stats.songs.total
                    }}
                  </b-card-text>
                </span>
              </span>

              <!-- Column: Progress -->
              <span v-else-if="props.column.field === 'result'">
                  <b-card-text class="mb-0 font-weight-bolder">
                    {{
                      calculate_result(
                          props.row.votes_stats.albums.rated,
                          props.row.votes_stats.videos.rated,
                          props.row.votes_stats.songs.rated,
                          props.row.votes_stats.albums.total,
                          props.row.votes_stats.videos.total,
                          props.row.votes_stats.songs.total
                      )
                    }}
                  </b-card-text>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>

        </b-col>
      </b-row>
    </section>

  </div>
</template>

<script>
import { BAvatar, BAvatarGroup, BCol, BFormCheckbox, BMediaBody, BRow, BProgress, BProgressBar, BCardText } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'

/*eslint-disable */
export default {
  components: {
    BCardActions,
    BFormCheckbox,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BProgress,
    BProgressBar,
    BCardText,
  },
  data() {
    return {
      search_query: '',
      usersList: [],
      filter: {
        onlyUnrated: false,
      },
      showOnlyTitles: false,
      columns: [
        {
          label: 'Имя',
          field: 'name',
        },
        {
          label: 'Альбомы RU',
          field: 'albums_ru',
        },
        {
          label: 'Альбомы EN',
          field: 'albums_en',
        },
        {
          label: 'Клипы RU',
          field: 'videos_ru',
        },
        {
          label: 'Клипы EN',
          field: 'videos_en',
        },
        {
          label: 'Песни RU',
          field: 'songs_ru',
        },
        {
          label: 'Песни EN',
          field: 'songs_en',
        },
        {
          label: 'Оценки',
          field: 'votes_count',
        },
        {
          label: 'Результат',
          field: 'result',
        },
      ],
    }
  },
  props: ['lang'],
  filters: {
    formatDate: function (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    },
  },
  methods: {
    calculate_result(rated_albums, rated_videos, rated_songs, total_albums, total_videos, total_songs) {
      let rated_score, total_score
      rated_score = rated_albums * 10 + rated_videos * 3 + rated_songs
      total_score = total_albums * 10 + total_videos * 3 + total_songs

      return Math.round(rated_score / total_score * 100);
    },
    variant(percent) {
      if (percent < 40) {
        return 'danger'
      } else {
        if (percent < 60) {
          return 'warning'
        }
        return 'success'
      }
    },
  },
  created() {
    this.$http.get('/users/votes').then(res => {
      this.usersList = res.data.data
    })
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>